<template>
  <div>
    <TemplatesList></TemplatesList>
  </div>
</template>
<script>
import TemplatesList from "../../components/templates/TemplatesList";
export default {
  name:"Templates-List",
  components: {
    TemplatesList
  },
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
</style>