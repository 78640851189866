<template>
  <section class="template-dashboard-view templates-list-view bt">
    <div class="inner-navbar">
      <div class="left-title">
        <div class="icon-block">
          <div class="icon" style="fill:var(--primary-color)">
            <icons :iconName="'Templates'"></icons>&nbsp;&nbsp;
          </div>
          <div class="icon-text">
            <h3
              class="title fw-normal text-dark mb-0 ls-05"
              v-if="
                this.$route.query &&
                this.$route.query.group &&
                getSingleEntityGroupData &&
                getSingleEntityGroupData.name
              "
            >
              {{ getSingleEntityGroupData.name }}
            </h3>
            <h3 class="title fw-normal text-dark mb-0 ls-05" v-else>
              Form Templates
            </h3>
          </div>
        </div>
      </div>
      <div class="right-block">
        <div
          v-if="
            selected_templates &&
            selected_templates.length &&
            getActiveWorkspace &&
            getActiveWorkspace.plan_type != 'INDIVIDUAL'
          "
        >
          <button
            class="btn btn-sm btn-outline-primary add-button float-right mr-3"
            type="button"
            @click="addToCompanyEntity"
          >
            <i class="fa fa-plus"></i> Add to company profile
          </button>
        </div>
        <div class="right-search-input">
          <el-input
            placeholder="Search"
            prefix-icon="el-icon-search"
            size="small"
            v-model="search_string"
            @input="searchString"
            clearable
            class="search_input float-right"
          ></el-input>
        </div>
        <div
          v-if="checkPermissionByPermissionName('createFormTemplate')"
          class="right-create-button"
        >
          <!-- <router-link to="/template/create"> -->
          <!-- <button
            class="btn add-button float-right mt-0"
            @click="createTemplate"
          >
            <span class="el-icon-circle-plus-outline pr-1"></span>
            Create Template
          </button> -->
          <button
            @click="scratchOrAi"
            class="btn btn-sm btn-outline-primary m-lr-1"
          >
            <i class="fa fa-plus-circle"></i>
            {{ getIsMobile ? "" : "Create Template" }}
          </button>
          <!-- </router-link> -->
        </div>
      </div>
    </div>
    <div class="inner-navbar-content top">
      <div class="vue-data-table-default" v-loading="loading">
        <data-tables-server
          :data="data"
          :total="total"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :pagination-props="paginationProps"
          @query-change="loadData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @sort-change="sortChange"
          v-if="!getIsMobile"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column id="name" width="50">
            <template id="category" slot-scope="scope">
              <span>
                <icons
                  :iconName="
                    scope.row.icon ? scope.row.icon : 'Block-Portfolio'
                  "
                ></icons>
              </span>
            </template>
          </el-table-column>

          <el-table-column
            label="Name"
            id="name"
            prop="name"
            min-width="200"
            sortable="custom"
          >
            <template v-if="scope.row.name" slot-scope="scope" id="category">
              <span
                class="cursor-pointer"
                @click="goToEditTemplate(scope.row._id)"
                >{{ scope.row.name }}</span
              >
            </template>
          </el-table-column>

          <el-table-column prop="action" min-width="100px">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link">
                  Actions
                  <i class="el-icon-caret-bottom el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <a
                    @click="onCustomTemplateView(scope.row)"
                    style="border: none"
                    ><el-dropdown-item
                      icon="el-icon-view"
                      command="edit"
                      :disabled="scope.row.type === 'STANDARD'"
                      >View</el-dropdown-item
                    ></a
                  >
                  <a @click="onEdit(scope.row)" style="border: none"
                    ><el-dropdown-item
                      icon="el-icon-edit-outline"
                      command="edit"
                      >Edit</el-dropdown-item
                    ></a
                  >
                  <a
                    @click="openDuplicateTemplateModal(scope.row)"
                    style="border: none"
                    ><el-dropdown-item
                      icon="el-icon-copy-document"
                      command="edit"
                      :disabled="scope.row.type === 'STANDARD'"
                      >Duplicate Template</el-dropdown-item
                    ></a
                  >
                  <a @click="onDelete(scope.row)" style="border: none">
                    <el-dropdown-item icon="el-icon-delete" command="delete"
                      >Delete</el-dropdown-item
                    ></a
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>

          <el-table-column label="Description" id="description" min-width="200">
            <template slot-scope="scope">
              <span
                class="cursor-pointer"
                @click="goToEditTemplate(scope.row._id)"
                style="word-break: break-word"
                >{{ scope.row.description }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            label="Created At"
            id="created_at"
            min-width="170"
            sortable="custom"
            prop="created_at"
          >
            <template slot-scope="scope" v-if="scope.row.created_at">
              <span class="fs-8 d-inline-block pr-2 text-muted">
                {{ scope.row.created_at | globalDateTimeFormat }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="Last Modified"
            id="updated_at"
            min-width="170"
            sortable="custom"
            prop="updated_at"
          >
            <template slot-scope="scope" v-if="scope.row.updated_at">
              <span class="fs-8 d-inline-block pr-2 text-muted">
                {{ scope.row.updated_at | globalDateTimeFormat }}
              </span>
            </template>
          </el-table-column>
        </data-tables-server>

        <data-tables-server
          :fullscreen="true"
          :data="data"
          :total="total"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :pagination-props="paginationProps"
          @query-change="loadData"
          style="width: 100%"
          v-if="getIsMobile"
          class="form-template-list-mobile"
        >
          <el-table-column type="expand">
            <template slot-scope="scope">
              <div class="expand-column-item">
                <div class="expand-column-item-label">Description:</div>
                <div class="expand-column-item-content">
                  <span :class="scope.row.status.toLowerCase() + ' circle'">
                    {{ scope.row.description }}
                  </span>
                </div>
              </div>
              <div class="expand-column-item">
                <div class="expand-column-item-label">Created At:</div>
                <div class="expand-column-item-content">
                  <template v-if="scope.row.created_at">
                    <span class="fs-8 d-inline-block pr-2 text-muted">
                      {{ scope.row.created_at | globalDateTimeFormat }}
                    </span>
                  </template>
                </div>
              </div>
              <div class="expand-column-item">
                <div class="expand-column-item-label">Last Modified:</div>
                <div class="expand-column-item-content">
                  <template v-if="scope.row.updated_at">
                    <span class="fs-8 d-inline-block pr-2 text-muted">
                      {{ scope.row.updated_at | globalDateTimeFormat }}
                    </span>
                  </template>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column id="name" width="40" fixed>
            <template id="category" slot-scope="scope">
              <span>
                <icons
                  :iconName="
                    scope.row.icon ? scope.row.icon : 'Block-Portfolio'
                  "
                ></icons>
              </span>
            </template>
          </el-table-column>

          <el-table-column
            label="Name"
            id="name"
            prop="name"
            min-width="120"
            fixed
          >
            <template v-if="scope.row.name" slot-scope="scope" id="category">
              <span
                class="cursor-pointer"
                @click="goToEditTemplate(scope.row._id)"
                >{{ scope.row.name }}</span
              >
            </template>
          </el-table-column>

          <el-table-column prop="action" min-width="100" fixed>
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link">
                  Actions
                  <i class="el-icon-caret-bottom el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <a
                    @click="onCustomTemplateView(scope.row)"
                    style="border: none"
                    ><el-dropdown-item
                      icon="el-icon-view"
                      command="edit"
                      :disabled="scope.row.type === 'STANDARD'"
                      >View</el-dropdown-item
                    ></a
                  >
                  <a @click="onEdit(scope.row)" style="border: none"
                    ><el-dropdown-item
                      icon="el-icon-edit-outline"
                      command="edit"
                      >Edit</el-dropdown-item
                    ></a
                  >
                  <a @click="onDelete(scope.row)" style="border: none">
                    <el-dropdown-item icon="el-icon-delete" command="delete"
                      >Delete</el-dropdown-item
                    ></a
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </data-tables-server>
      </div>
    </div>
    <dialog-component
      v-loading.fullscreen.lock="duplicateLoading"
      :title="'Duplicate Template'"
      :visible="duplicateFromTemplateDialogVisible"
      :containerWidth="'23%'"
      @before-close="closeDialog"
      v-draggable
    >
      <div class="popUp-content">
        <label>Title :</label>
        <el-input
          type="text"
          @keyup.enter.native="handleEnterKey()"
          placeholder="Title"
          v-model="duplicateTemplateName"
        >
        </el-input>
      </div>
      <span slot="footer">
        <button
          class="btn btn btn-outline-danger btn-sm m-lr-1"
          @click="closeDuplicateTemplateModal"
        >
          <i class="fa fa-close"></i> Cancel
        </button>
        <button
          class="btn btn btn-outline-primary btn-sm m-lr-1"
          @click="duplicateTemplate()"
          :disabled="!duplicateTemplateName"
        >
          <i class="fa fa-check-square-o"></i> Confirm
        </button>
      </span>
    </dialog-component>

    <dialog-component
      title="Create Template"
      :visible="dialogVisible"
      v-loading="createTemplateLoading"
      element-loading-text="Checking template Name"
      :containerMaxWidth="getIsMobile ? '80%' : '50%'"
      :containerMinHeight="getIsMobile ? '50%' : '50%'"
      @before-close="dialogVisible = false"
    >
      <div class="dialog-content">
        <el-row :gutter="20">
          <el-col :span="12">
            <div
              class="option"
              @click="selectOption('template')"
              :style="{
                borderColor: selectedOption === 'template' ? '#409eff' : '',
              }"
            >
              <div class="icons plus-symbol">
                <i class="el-icon-plus"></i>
              </div>
              <div class="Title">Start from scratch</div>
              <div class="description">
                Create a new form template by simply dragging and dropping from
                a list of available fields.
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="option option-card"
              @click="selectOption('ai')"
              :style="{ borderColor: selectedOption === 'ai' ? '#409eff' : '' }"
            >
              <div class="icons star-symbol">
                <img src="@/assets/AI Icon.svg" />
              </div>
              <div class="Title">Create with AI</div>
              <div class="description">
                Provide some details, and AI will create a complete form
                template for you.
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-if="form_field" class="mt-1">
        <el-form>
          <el-form-item label="Form Template Name">
            <span class="text-danger">*</span>
            <el-input
              type="text"
              placeholder="Enter the form template name"
              v-model="formTemplateName"
              @keydown.enter.native.prevent="handleEnter"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small"
          >Cancel</el-button
        >
        <el-button
          type="primary"
          @click="createTemplate('ai')"
          size="small"
          :disabled="!formTemplateName"
          >Create</el-button
        >
      </span>
    </dialog-component>
    <el-dialog title="Preview Template" :visible.sync="previewDialogVisible">
      <template>
        <PreviewTemplate
          :name="selectedCompanyTemplate.name"
          :fieldsData="selectedCompanyTemplate.fieldsData"
          v-if="previewDialogVisible"
        ></PreviewTemplate>
      </template>
    </el-dialog>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { TimelineLite, Bounce, Elastic } from "gsap/dist/gsap";
import PermissionsHelper from "@/mixins/permissionsHelper";
import NavigationHelper from "@/mixins/navigationHelper";
import {
  getAllCompanyTemplatesByPagination,
  deleteTemplate,
  addSingleTemplateToLocal,
} from "../../repo/templatesRepo";
import { postAPICall } from "@/helpers/httpHelper";
export default {
  name: "templates-Templates-List",
  computed: {
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("companyTemplates", [
      "getAllCompanyTemplatesData",
      "getTemplateDeleteStatus",
      "getTemplateDeleteError",
      "getDuplicateFormTemplateError",
      "getDuplicateFormTemplateStatus",
    ]),
    ...mapGetters("entities", [
      "getAddEntityToCompanyStatus",
      "getCompanyEntity",
    ]),
    ...mapGetters("auth", ["getActiveWorkspace"]),

    ...mapGetters("entityGroups", ["getSingleEntityGroupData"]),
    ...mapGetters("roles", ["getRoleById", "getSuccess", "getRoleErrors"]),

    ...mapGetters("auth", ["getAuthenticatedUser"]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
  },

  components: {
    PreviewTemplate: () => import("./PreviewTemplate"),
  },
  mixins: [PermissionsHelper, NavigationHelper],
  async mounted() {
    this.timeline = new TimelineLite({
      // onComplete: () => this.timeline.restart()
    });
    await this.fetchGroupDetails();
    this.fetchAllCompanyTemplates();
    this.animateStatSections();
  },
  data() {
    return {
      formTemplateId: null,
      pageSizes: [10, 20, 50],
      duplicateTemplateName: "",
      duplicateLoading: false,
      duplicateFromTemplateDialogVisible: false,
      loading: false,
      selectedOption: null,
      selectedCompanyTemplate: {},
      total: 0,
      currentPage: 1,
      pageSize: 0,
      order_type: null,
      order_by: null,
      data: [],
      previewDialogVisible: false,
      timeline: null,
      search_string: "",
      temp_total: 0,
      selected_templates: [],
      dialogVisible: false,
      AddDialogVisible: false,
      form_field: false,
      formTemplateName: "",
      createTemplateLoading: false,
    };
  },
  beforeDestroy() {
    this.$store.commit(
      "navigationOpen/setCurrentMenu",
      {},
      {
        root: true,
      }
    );

    this.$store.commit(
      "companyTemplates/setAllCompanyTemplatesData",
      {
        data: [],
      },
      {
        root: true,
      }
    );
  },
  methods: {
    async fetchGroupDetails() {
      if (this.$route.query.group && this.$route.query.group.length) {
        await this.$store.dispatch(
          "entityGroups/fetEntityGroupDataById",
          this.$route.query.group
        );
      }
      if (this.getAuthenticatedUser && this.getAuthenticatedUser.activeRole) {
        await this.$store.dispatch(
          "roles/fetchRoleById",
          this.getAuthenticatedUser.activeRole.role_id
        );
      }
    },
    closeDialog() {
      this.duplicateFromTemplateDialogVisible = false;
    },
    scratchOrAi() {
      this.dialogVisible = true;
    },

    formOpen() {
      this.form_field = true;
    },

    handleEnter() {
      this.createTemplate("ai");
    },
    selectOption(option) {
      this.selectedOption = option;
      if (option === "template") {
        this.createTemplate("template");
      } else if (option === "ai") {
        this.formOpen();
      }
    },

    openDuplicateTemplateModal(template) {
      this.duplicateTemplateName = template.name + "-Dup";
      this.formTemplateId = template._id;
      this.duplicateFromTemplateDialogVisible = true;
    },
    closeDuplicateTemplateModal() {
      this.duplicateFromTemplateDialogVisible = false;
    },
    getTemplateSlug() {
      return this.duplicateTemplateName
        .trim()
        .toLowerCase()
        .split(" ")
        .join("-");
    },
    checkPermissions() {
      if (!this.checkPermissionByPermissionName("createFormTemplate")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for create template. Please contact owner.!",
        });
      }
    },
    async addToCompanyEntity() {
      try {
        this.loading = true;
        let params = {
          templates: this.selected_templates.flatMap((e) => e._id),
        };
        await this.$store.dispatch("entities/addCompanyEntity", params);
        if (this.getAddEntityToCompanyStatus) {
          let alreadyAdded = this.selected_templates
            .filter((tmp) =>
              this.getCompanyEntity.templates
                .map((t) => t.template_id)
                .includes(tmp._id)
            )
            .map((tmp) => tmp.name);
          let message =
            `${params.templates.length - alreadyAdded.length}` +
            " templates added to company profile successfully. ";
          message += alreadyAdded.length
            ? alreadyAdded.join(", ") +
              (alreadyAdded.length > 1 ? " were" : " was") +
              " already in the company profile."
            : "";
          this.loading = false;
          this.$notify.success({
            title: "Success",
            message: message,
          });
          this.fetchAllCompanyTemplates();
        } else {
          this.loading = false;
          this.$notify.error({
            title: "Error",
            message: "Error on adding templates",
          });
        }
      } catch (er) {
        console.log("addToCompanyEntity", er);
      }
    },
    async sortChange(a) {
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      this.fetchAllCompanyTemplates();
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        this.fetchAllCompanyTemplates();
      }
    },
    handleSelectionChange(val) {
      this.selected_templates = [];
      this.selected_templates = val;
    },
    async fetchAllCompanyTemplates() {
      this.loading = true;
      if (this.pageSize == 0) {
        this.pageSize = 10;
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
      };
      if (this.order_by) {
        params.order_by = this.order_by;
      }
      if (this.order_type) {
        params.order_type = this.order_type;
      }
      if (this.$route.query.group && this.$route.query.group.length) {
        params.group = this.$route.query.group;
      }
      if (this.getAuthenticatedUser && this.getAuthenticatedUser.activeRole) {
        if (this.getRoleById && this.getRoleById) {
          params.groups = this.getRoleById.allowed_groups;
        }
      }

      if (this.search_string) {
        params.search_string = this.search_string;
      }
      // await this.$store.dispatch(
      //   "companyTemplates/getAllCompanyTemplates",
      //   params
      // );
      const getAllCompanyTemplatesData =
        await getAllCompanyTemplatesByPagination(params);

      // this.currentPage = this.getAllCompanyTemplatesData.page
      //   ? parseInt(this.getAllCompanyTemplatesData.page)
      //   : 1;
      this.total = getAllCompanyTemplatesData.total
        ? parseInt(getAllCompanyTemplatesData.total)
        : 0;
      this.data = getAllCompanyTemplatesData.data || [];
      this.loading = false;
    },
    async refreshTemplates() {
      this.currentPage = 1;
      this.pageSize = 10;
      this.fetchAllCompanyTemplates();
    },
    searchString() {
      this.pageSize = 10;
      this.page = 1;
      this.fetchAllCompanyTemplates();
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchAllCompanyTemplates();
      }
    },
    async goToEditTemplate(templateId) {
      if (!this.checkPermissionByPermissionName("editFormTemplate")) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for edit template. Please contact owner! ",
        });
      } else {
        let query = this.getNavigationQuery(this.$route.query);
        this.$router.push({
          name: "Edit Templates",
          params: { template_id: templateId },
          query,
        });
      }
    },

    async createTemplate(type) {
      try {
        let query = this.getNavigationQuery(this.$route.query);
        if (type != "template") {
          this.createTemplateLoading = true;
          let resp = await postAPICall(
            "POST",
            `/template/checkTemplateExists`,
            {
              template_name: this.formTemplateName,
            }
          );
          this.createTemplateLoading = false;
          if (!resp?.success) {
            return this.$message.error(
              "Template Already Exists with same name"
            );
          }
        }
        let path =
          type == "template"
            ? "/template/create"
            : `/template/create-with-ai?template_name=${this.formTemplateName}`;
        this.$router.push({
          path: path,
          query,
        });
      } catch (err) {
        this.createTemplateLoading = false;
        return this.$message.error("Template Already Exists with same name");
      }
    },

    async goToViewTemplate(templateId) {
      let query = this.getNavigationQuery(this.$route.query);
      this.$router.push({
        name: "ViewTemplate",
        params: { template_id: templateId },
        query,
      });
    },

    onEdit(template) {
      if (
        !this.checkPermissionByPermissionNameFormTemplates(
          template,
          "editFormTemplate"
        )
      ) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for edit template. Please contact owner! ",
        });
      } else {
        this.goToEditTemplate(template._id);
      }
    },
    onView(template) {
      let templateCopy = JSON.parse(JSON.stringify(template));
      this.selectedCompanyTemplate.name = "";
      this.selectedCompanyTemplate.fieldsData = [];
      this.selectedCompanyTemplate.name = templateCopy.name;

      if (templateCopy.type == "CONFIGURABLE") {
        let defaultSection = templateCopy.sections.find(
          (x) => x && x.type && x.type == "DEFAULT"
        );
        this.selectedCompanyTemplate.fieldsData = defaultSection.fields.map(
          (x) => {
            if (x.options && x.options.length) {
              x.options = x.options.join(",");
            }
            return x;
          }
        );
        this.previewDialogVisible = true;
      }
    },
    async duplicateTemplate() {
      this.duplicateLoading = true;
      let params = {
        name: this.duplicateTemplateName,
        templateId: this.formTemplateId,
        slug: this.duplicateTemplateName
          .trim()
          .toLowerCase()
          .split(" ")
          .join("-"),
      };
      try {
        const response = await postAPICall(
          "POST",
          "/template/duplicateFormTemplate",
          params
        );
        if (response?.data) {
          addSingleTemplateToLocal(response.data);
        }
        this.duplicateLoading = false;
        this.duplicateFromTemplateDialogVisible = false;
        await this.fetchAllCompanyTemplates();
      } catch (e) {
        this.$notify.error({
          title: "Error",
          message:
            e?.response?.data?.message || "Error in duplicating form template",
        });
        this.duplicateLoading = false;
      }
      // await this.$store.dispatch(
      //   "companyTemplates/duplicateFormTemplate",
      //   params
      // );
      // this.duplicateLoading = false;
      // if (this.getDuplicateFormTemplateStatus) {
      //   this.$notify.success({
      //     title: "Success",
      //     message: "Template Duplicated Successfully.",
      //   });
      //   this.duplicateFromTemplateDialogVisible = false;
      //   this.duplicateLoading = false;
      //   await this.fetchAllCompanyTemplates();
      // } else {
      //   this.$notify.error({
      //     title: "Error",
      //     message: this.getDuplicateFormTemplateError
      //       ? this.getDuplicateFormTemplateError
      //       : "Error in duplicating form template",
      //   });
      // }
    },
    onCustomTemplateView(template) {
      if (
        !this.checkPermissionByPermissionNameFormTemplates(
          template,
          "viewUserFormTemplate"
        )
      ) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for view template. Please contact owner! ",
        });
      } else {
        this.goToViewTemplate(template._id);
      }
    },
    async onDelete(row) {
      if (
        !this.checkPermissionByPermissionNameFormTemplates(
          row,
          "deleteFormTemplate"
        )
      ) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for delete templates. Please contact owner.!",
        });
      } else {
        this.$confirm("Are you sure to delete the Template?", "Warning", {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }).then(() => {
          this.deleteTemplate(row);
        });
      }
    },
    async deleteTemplate(template) {
      let params = {
        id: template._id,
      };
      await this.$store.dispatch(
        "companyTemplates/deleteCompanyTemplate",
        params
      );
      if (this.getTemplateDeleteStatus) {
        this.$notify.success({
          title: "Success",
          message: "Template deleted successfully",
        });
        await deleteTemplate(template._id);
        this.refreshTemplates();
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getTemplateDeleteError || "Error on deleting template",
        });
      }
    },

    animateStatSections() {
      const {
        statOne,
        statOneIcon,
        statOneContent,
        statTwo,
        statTwoIcon,
        statTwoContent,
        statThree,
        statThreeIcon,
        statThreeContent,
      } = this.$refs;

      // this.timeline.to([statOne, statTwo, statThree], 0, {
      //   autoAlpha:0
      // })

      this.timeline
        .from(statOne, 1.5, {
          autoAlpha: 0,
          scale: 1.25,
          ease: Bounce.easeOut,
        })
        .from(
          statOneContent,
          1.5,
          {
            autoAlpha: 0,
            scale: 0,
            ease: Elastic.easeInOut,
          },
          "-=1.5"
        )
        .from(
          statOneIcon,
          1.5,
          {
            opacity: 0,
            scale: 1.25,
            ease: Bounce.easeInOut,
          },
          "-=1"
        );

      this.timeline
        .from(
          statTwo,
          1.5,
          {
            autoAlpha: 0,
            scale: 1.25,
            ease: Bounce.easeOut,
          },
          "-=1"
        )
        .from(
          statTwoContent,
          1.5,
          {
            autoAlpha: 0,
            scale: 0,
            ease: Elastic.easeInOut,
          },
          "-=1.5"
        )
        .from(
          statTwoIcon,
          1.5,
          {
            opacity: 0,
            scale: 1.25,
            ease: Bounce.easeInOut,
          },
          "-=1"
        );

      this.timeline
        .from(
          statThree,
          1.5,
          {
            autoAlpha: 0,
            scale: 1.25,
            ease: Bounce.easeOut,
          },
          "-=1"
        )
        .from(
          statThreeContent,
          1.5,
          {
            autoAlpha: 0,
            scale: 0,
            ease: Elastic.easeInOut,
          },
          "-=1.5"
        )
        .from(
          statThreeIcon,
          1.5,
          {
            opacity: 0,
            scale: 1.25,
            ease: Bounce.easeInOut,
          },
          "-=1"
        );
    },
    handleEnterKey() {
      this.duplicateTemplate();
    },
  },

  watch: {
    "$route.query.group"() {
      this.fetchAllCompanyTemplates();
      this.animateStatSections();
    },
    "$route.query.key"() {
      this.fetchAllCompanyTemplates();
      this.animateStatSections();
    },
  },
};
</script>
<style lang="scss" scoped>
.inner-navbar-content.top {
    margin-top: -15px !important;
}
.template-dashboard-view {
  // width: 90%;
  // display: table;
  margin: 0 auto;
  .search_input {
    // height: 29px !important;
  }
  .add-button {
    height: 32px;
    border: 1.5px solid #285ed3;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0em 0.65em;
    color: #285ed3;
    background: #ecf5ff;
    border-color: #b3d8ff;
    &:hover {
      background: #409eff;
      border-color: #409eff;
      color: #ffffff;
    }
  }
  .inner-navbar-content {
    margin-top: 20px;
  }
  .guide-stats-block {
    padding: 1em 0 2em;
    .each-stat {
      position: relative;
      background-color: #ffffff;
      border-radius: 8px;
      padding: 1em 1em 0.75em;
      color: #ffffff;
      overflow: hidden;
      min-height: 105px;
      &.stat-card-one {
        background-color: #ff83a1;
      }
      &.stat-card-two {
        background-color: #5673dc;
      }
      &.stat-card-three {
        background-color: #ffbd12;
      }
      .icon {
        position: relative;
        z-index: 3;
        img {
          max-height: 50px;
        }
      }
      .cover {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        z-index: 1;
        img {
          width: 100%;
        }
      }
      .stat-content {
        position: relative;
        z-index: 2;
      }
      h3 {
        font-size: 3em;
        font-weight: 300;
        line-height: 1;
        text-align: center;
      }
      p {
        position: absolute;
        text-align: right;
        bottom: 0;
        right: 0;
      }
    }
    .usage-guide {
      position: relative;
      min-height: 105px;
      display: flex;
      background-color: #ebf0f3;
      border-radius: 8px;
      padding: 1em 1em 0.75em;
      overflow: hidden;
      .content {
        position: relative;
        z-index: 2;
      }
      .icon-right {
        width: 200px;
        position: relative;
        z-index: 3;
      }
      h4 {
        font-size: 1em;
        margin: 0;
        font-weight: 400;
      }
      p {
        font-size: 0.75em;
        line-height: 1.3;
        margin: 0.25em 0 0.75em;
      }
      .el-button {
        font-size: 0.85em;
        padding: 0.35em 0.75em;
        background-color: #6979f8;
        &:hover {
          background-color: #4558e9;
        }
      }

      .cover {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        z-index: 1;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.templates-list-view {
  .el-table {
    td:first-child {
      .cell {
        text-overflow: unset !important;
      }
    }
  }

  .el-table__cell {
    padding: 5px 5px !important;
    font-size: 14px;
  }
}
</style>
<style lang="scss" scoped>
@media(min-width:768px){
  .left-title{
    margin-top:10px;
  }
  .right-search-input{
    margin-top:20px;
  }
  .right-create-button{
    margin-top:20px;
  }
}
@media(max-width: 426px){
  .right-search-input{
    margin-bottom: 30px !important;
    margin-left: 50px !important;
    margin-top:-30px;
    float:right !important;
 
  }
  .right-create-button{
    margin-bottom: 20px !important;
    float:right !important;
    margin-top:-30px;
    
  }
}

.inner-navbar {
  display: flex;
  justify-content: space-between;
  @media (max-width: 650px) {
    flex-direction: column;
    .left-title {
      margin-bottom: 10px;
    }
  }
  .left-title {
    .icon-block {
      display: flex;
      margin-top: 10px;
      .icon {
        width: 30px;
        margin-right: 10px;
      }
      .icon-text {
      }
    }
  }
  .right-block {
    display: flex;
    .right-search-input {
      margin-right: 10px;
      // height: 50px;
      @media (max-width: 650px) {
        margin-right: 0px;
        margin-bottom: 10px;
      }
    }
    .right-create-button {
    }
    @media (max-width: 650px) {
      align-items: end;
    }
  }
}
</style>
<style lang="scss">
.form-template-list-mobile {
  .el-table__body {
    .el-table__row {
      & > td {
        &:first-child {
          padding: 0px;
        }
      }
    }
  }
}
</style>
<style scoped>
.bt .btn-outline-primary {
  color: var(--primary-contrast-color);
  background: var(--primary-color);
  border-color: var(--primary-color);
  height: 32px !important;
  &:hover {
    color: var(--primary-contrast-color);
    background: var(--primary-color);
    border-color: var(--primary-color);
  }
  &:active:focus {
    color: var(--primary-contrast-color) !important;
    background: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    box-shadow: 0 0 0 0.2rem var(--primary-color) !important;
  }
  @media (max-width: 767px) {
    position: relative;
    bottom: 10px;
  }
}
</style>
<style scoped>
.dialog-content {
  text-align: center;
}
.option {
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s;
  width: 206px;
  height: 242px;
  margin-top: 10px;
}

.icons {
  font-size: 36px;
  color: #409eff;
}
.el-icon-plus {
  margin-top: 40px;
}
.plus-symbol {
  background: #f5f9ff;
  width: 100%;
  height: 50%;
  border-radius: 4px;
}
.star-symbol {
  background: #f5f9ff;
  z-index: 1;
  width: 100%;
  height: 50%;
  border-radius: 4px;
}
.star-symbol img {
  margin-top: 36px;
}
.Title {
  font-size: 20px;
  margin-top: 10px;
  font-weight: bold;
  color: #303133;
  text-align: left;
}
.description {
  font-size: 12px;
  color: #303133;
  margin-top: 10px;
  text-align: left;
  line-break: normal;
  white-space: pre-line;
}

@media (max-width: 767px) {
  .option {
    height: 40%;
    width: 65%;
    margin-left: 1px;
  }
  .el-icon-plus {
    position: relative;
    bottom: 10px;
  }
  ::v-deep .star-symbol {
    height: 60%;
  }
  .option-card img {
    margin-top: 33px;
  }
  .option-card .Title {
    margin-top: 20px;
  }
  .option-card .description {
    margin-top: 47px;
  }
  .option-card {
    margin-top: 10px;
  }
}
</style>
